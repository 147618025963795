import { TextoService } from 'src/app/services/texto.service';
import { EventEmitterService } from './event-emitter.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TermoAceiteService {

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private TextoService: TextoService,
    private router: Router,
  ) { }


  enviarpdfBeneficiario(termo: string, ip: string){
    this.httpClient.post<any>(`${environment.apiBase}termo/beneficiario/aceite`, {
     termo,
     ip
    }).subscribe(
      (x) => {
        this.router.navigate(["/detalhes"])
      },
      (err) => {
        this.messageService.showError(this.TextoService.ErroGenerico)
        EventEmitterService.get('AlteraLoading').emit(false)
      }
    )
  }

  // Não está trazendo o CPF até o momento. Parte 2 do projeto;
  enviarpdfProfissional(termo: string, ip: string){
    this.httpClient.post<any>(`${environment.apiBase}termo/profissional/aceite`, {
     termo,
     ip
    }).subscribe(
      (x) => {
        this.router.navigate(["/atendimento"])
      },
      (err) => {
        this.messageService.showError(this.TextoService.ErroGenerico)
        EventEmitterService.get('AlteraLoading').emit(false)
      }
    )
  }

  visuTermoBeneficiario() : Observable<any>{
    return this.httpClient.get(`${environment.apiBase}termo/beneficiario/visualizar`,{ responseType: 'blob' })
  }

}

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { MessageService } from 'src/app/services/message.service';
import { TextoService } from 'src/app/services/texto.service';
import { Beneficiario } from 'src/app/types/Beneficiario';
import { ProgressSpinnerDialogComponent } from '../../ui/progress-spinner-dialog/progress-spinner-dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-page-upload-termos',
  templateUrl: './page-upload-termos.component.html',
  styleUrls: [ './page-upload-termos.component.scss' ],
  host: { class: 'full-width' },
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class PageUploadTermosComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;

  param: string;
  handle: string;
  finalizado = false;
  secondFormGroup: FormGroup;
  image: string | ArrayBuffer;
  beneficiario: Beneficiario;
  progressSpinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;
  steps: any;

  constructor(
    public textoService: TextoService,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private beneficiarioService: BeneficiarioService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private autorizacaoService: AutorizacaoService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
  ) {

    // this.stepsData.defaultStepOptions.showCancelLink = true;
    // this.steps = (this.tour && this.tour.paginas.indexOf('upload') !== -1 || !this.deviceService.isDesktop()) ? [] : this.stepsData.steps.upload;
  }

  ngOnInit() {
    // this.beneficiarioService.consultaDadosBeneficiario(this.handle).subscribe((x: Beneficiario[]) => {
    //   this.beneficiario = [ ...x ].pop();
    //   /*this.shepherdService.start()*/
    // });
  }

  }

  // imprimirTermo() {
  //   const tab = window.open();
  //   this.showProgressSpinner();
  //   tab.document.write(this.textoService.CarregandoLaudosAguarde);
  //   tab.document.title = 'Unimed Presidente Prudente';

  //   this.autorizacaoService.postImprimeTermo({
  //     beneficiario: this.handle,
  //   })
  //     .subscribe(
  //       (blob: Blob) => {
  //         if (!!blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
  //           window.navigator.msSaveBlob(blob, this.beneficiario.nome.replace(/\s/g, '_') + 'autorizacao.pdf');
  //           tab.close();
  //           return this.avancar(this.stepper);
  //         }

  //         if (!!blob) {
  //           tab.location.href = URL.createObjectURL(blob);
  //           tab.document.title = 'Unimed Presidente Prudente';

  //           return this.avancar(this.stepper);
  //         }

  //         tab.close();
  //         return this.messageService.showError(this.textoService.ErroAbrirDocumento);
  //       },
  //       erro => {
  //         tab.close();
  //         this.messageService.showError(erro.message || this.textoService.ErroGenerico);
  //       },
  //     ).add(() => this.progressSpinnerDialogRef.close());
  // }

  // termoInputChange(event: any) {
  //   this.readThis(event.target);
  // }

  // readThis(inputValue: any) {
  //   const file: File = inputValue.files[0];
  //   const reader: FileReader = new FileReader();

  //   reader.onloadend = () => this.image = reader.result;
  //   reader.readAsDataURL(file);
  // }

  // enviarTermo(stepper: MatStepper) {
  //   this.showProgressSpinner();
  //   const base64 = this.image;
  //   this.image = null;
  //   const payload = {
  //     TermoImage: base64.toString().replace(/^data:image\/(png|jpg);base64,/, ''),
  //     Beneficiario: this.beneficiario.handle,
  //     Matricula: this.beneficiario.matricula.handle,
  //     Aceite: true,
  //   };
  //   this.beneficiarioService.postTermoBeneficiario(payload)
  //     .subscribe(
  //       (result: any) => !result.data.aceite || this.complete(stepper),
  //       (error: any) => this.messageService.showError(this.textoService.ErroAoExecutarSolicitacao + error.message),
  //     ).add(() => this.progressSpinnerDialogRef.close());
  // }

  // avancar(stepper: MatStepper) {
  //   stepper.next();
//   }

//   complete(stepper: MatStepper) {
//     stepper.selected.completed = true;
//     this.finalizado = true;
//     stepper.next();
//   }

//   showProgressSpinner() {
//     this.progressSpinnerDialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
//       panelClass: 'progress-spinner',
//       disableClose: true,
//     });
//   }
// }


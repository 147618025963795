import {version as appVersion} from '../../package.json';

export const environment = {
  production: true,
  version: appVersion,
  sistema: 1,
  youtubeVideos: [
    {
      titulo: 'manual_cooperado',
      id: '_eJXusftu9g'
    },
    {
      titulo: 'manual_beneficiario',
      id: '2d2OP8INGzU'
    },
  ],
  documentos: [
    {
      titulo: 'manual_cooperado',
      nome: 'Manual_banco_de_exames.pdf'
    },
  ],
  apiBase: 'https://services.unimedprudente.com.br/laudos/portal/',
  apiRemoteIP: 'https://ifconfig.me/all.json/'
};
